// 手机号
export const REGEX_MOBILE = /^1(3|4|5|6|7|8|9)\d{9}$/;

// 座机
export const REGEX_TEL = /^([0-9]{3,4}-)?[0-9]{7,8}$/;

// 银行卡
// export const REGEX_BANK_CODE = /^[1-9](\d{15}|\d{16}|\d{18})$/;
export const REGEX_BANK_CODE = /^([1-9]{1})(\d{14,19})$/;

// 身份证
export const REGEX_IP_CODE =
  /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;

// 统一社会信用代码
export const REGEX_CREDIT_CODE = /^[^_IOZSV\W]{2}\d{6}[^_IOZSV\W]{10}$/;

export default {
  REGEX_MOBILE,
  REGEX_TEL,
  REGEX_BANK_CODE,
  REGEX_IP_CODE,
  REGEX_CREDIT_CODE
};
