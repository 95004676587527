import { get, post } from "@/utils/https";

// 获取联系人信息
export function userContactsInfo(customerId) {
  return get(`/api/v1/common/cus/apply/${customerId}/contact/persons`);
}

// 联系人类型枚举
export function userContactsEnum() {
  return get(`/api/v1/common/saas/app/offline/apply/apply/contactPerson/types`);
}

// 提交保存联系人信息
export function userContactsSave(data) {
  if (!data.bizNo) throw "缺少bizNo";
  return post(
    `/api/v1/common/saas/app/offline/apply/apply/saveContactPersons/${data.bizNo}`,
    data.list
  );
}
